<template>
  <div>
    <div class="my-6 text-start">
      <h3 class="font-bold">Criar um novo Tipo de Produto</h3>
      <span class="text-sm text-gray-500"
        >Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>

      <div class="flex flex-row items-center">
        <div class="w-full p-2">
          <Input
            v-model="tipo.nome"
            type="default"
            label="Tipo de Produto"
            placeholder="Tipo de Produto"
          />
        </div>
        <div class="justify-center">
          <Button color="primary" text="Criar" @click="create" />
        </div>
      </div>


    <hr class="my-9" />

    <div class="card shadow-sm bg-white border p-8">
      <div class="my-6 text-start">
        <h3 class="font-bold">Lista de {{ route.name }}</h3>
        <span class="text-sm text-gray-500"
          >Aqui você pode observar todos os itens relacionados aos tipos de
          produtos como suas respectivas ações.
        </span>
      </div>

      <div class="card">
        <DataTable
          :headers="headers"
          :data="data"
          :options="options"
          :exports="true"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE, POST } from "../../services/api";
import { useRoute } from "vue-router";
import Input from "@/components/Elements/Input.vue";
import ProdutosJson from "../../../api.js/produtos.json";
import { isEmpty } from "../../services/validate";

export default {
  name: "TiposProdutos",
  components: {
    DataTable,
    Button,
    Input,
  },

  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Nome", value: "nome" },
      { key: "Ações", value: "" },
    ];

    const route = useRoute();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);
    const tipo = ref({
      nome: "",
      "id_envio": 2,
    });

    onMounted(async () => {
      data.value = await GET("tipo_produto");
    });

    /* const deleteItem = (item) => {
      loader.open();
      const deleteFunction = async function() {
        try {
          loader.open();
          const indexItem = data.value.indexOf(item)
          await DELETE(`usuarios_vinculos/${item.id}`);
          data.value.splice(indexItem, 1)
          
          loader.close();
          alert.open("Sucesso!", `Vínculo deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse vínculo?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };*/

    const create = async () => {
      const validate = isEmpty(tipo.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        loader.open();
        try {
          const response = await POST("tipo_produto", tipo.value);
          alert.open(
            "Sucesso!",
            `Evento <b>${tipo.value.nome}</b> criado com sucesso!`,
            "success"
          );

          tipo.value.id = response.identifiers[0].id;

          data.value.push(tipo.value);

          loader.close();
        } catch (e) {
          alert.open("Atenção!", e, "danger");
          loader.close();
        }
      }
    };

    const options = [
      {
        icon: "pen",
        path: "/tipo_produto/editar",
        title: "Editar Tipo Produto",
      },
      // { icon: "trash", action: (param) => deleteItem(param), title: "Excluir vínculo" },
    ];

    return { headers, data, options, route, create, tipo };
  },
};
</script>

<style>
.btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
</style>
